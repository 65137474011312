@font-face {
  font-family: 'SofiaPro Light';
  src: url('./fonts/SofiaProLight.ttf');
}

@font-face {
  font-family: 'SofiaPro Regular';
  src: url('./fonts/SofiaProRegular.ttf');
}

@font-face {
  font-family: 'SofiaPro Medium';
  src: url('./fonts/SofiaProMedium.ttf');
}

@font-face {
  font-family: 'SofiaPro SemiBold';
  src: url('./fonts/SofiaProSemiBold.ttf');
}

@font-face {
  font-family: 'SofiaPro Bold';
  src: url('./fonts/SofiaProBold.ttf');
}

.error-class{
  color: red;
}

:root{
  --white: #fff;
  --black: #0c0c0c;
  --gray: #BBBBBB;
  --silver: hsl(0, 0%, 94%);
  --logo-red: #d8262c;

  /* --silver: hsl(0, 0%, 94%);
  --yellow: #e7a837;
  --red: #c60f0f;
  --blue: #046b8b;

  --light-red: #f2585a;
  
  --light-green: #15F9CD;
  --medium-green: #37ED59;
  --dark-green: #2EB395;
  --pink: #F64081;
  --sky-blue: #3CBAF9;
  --purple: #c84ae3; */


  --primary: #e7a837 !important;

  --light-yellow: #EFDE97;
  --yellow: #EFD87A;
  --dark-yellow: #EFD25B;
  
  --light-red: #ED9F9D;
  --red: #ED8783;
  --dark-red: #ED6661;
  
  --light-blue: #92C2E0;
  --blue: #79B8E0;
  --dark-blue: #5AADE0;

  --light-green: #7CCCBD;
  --green: #62CCB8;
  --dark-green: #49CCB4;

  --light-purple: #C6A9E5;
  --purple: #BB95E5;
  --dark-purple: #A86FE5;
}

/* Background color */
.bg-light{
  background: var(--silver);
}

.bg-black{
  background: var(--black);
}

.bg-white{
  background: var(--white);
}

.bg-primary{
  background: var(--primary) !important;
}

.bg-light-yellow{
  background: var(--light-yellow);
}
.bg-yellow{
  background: var(--yellow);
}
.bg-dark-yellow{
  background: var(--dark-yellow);
}

.bg-light-blue{
  background: var(--light-blue);
}
.bg-blue{
  background: var(--blue);
}
.bg-dark-blue{
  background: var(--dark-blue);
}

.bg-light-red{
  background: var(--light-red);
}
.bg-red{
  background: var(--red);
}
.bg-dark-red{
  background: var(--dark-red)
}

.bg-light-green{
  background: var(--light-green);
}
.bg-green{
  background: var(--green);
}
.bg-dark-green{
  background: var(--dark-green);
}

.bg-light-purple{
  background: var(--light-purple);
}
.bg-purple{
  background: var(--purple);
}
.bg-dark-purple{
  background: var(--dark-purple);
}

.color-primary{
  color: var(--primary) !important;
}
.color-yellow{
  color: var(--yellow) !important;
}
.color-blue{
  color: var(--blue) !important;
}
.color-red{
  color: var(--red) !important;
}
.color-green{
  color: var(--green) !important;
}
.color-purple{
  color: var(--purple) !important;
}

/* Background color Ends */

/* COMMON USED CLASSES */
body{
  font-family: 'SofiaPro Light';
  color: var(--black);
  /* letter-spacing: -1px; */
  /* font-size: 0.9rem; */
  font-size: 1.1rem;
  line-height: 1.8rem;
}

a{
  cursor: pointer;
}

h1, h2, h3{
  letter-spacing: -1px;
}

.display-5 {
  font-size: 2rem;
}

.pt-105{
  padding-top: 140px;
}

.mtb-80 {
  margin: 60px 0;
  display:inline-block;
}

.mw-large{
  max-width: 1800px;
}

.pl-90 {
  padding-left: 90px;
}

.btn{
  border-radius: 0;
  text-transform: uppercase;
  padding: 1rem 3rem;
  font-size: 0.9rem;
  font-family: 'SofiaPro Medium';
  outline: none !important;
  box-shadow: none !important;
}
.btn-black {
  background: var(--black) !important;
  color: var(--white) !important;
  transition:0.3s;
  border: 2px solid var(--black) !important;
}

.btn-black:hover {
  background: var(--primary) !important;
  color: var(--black) !important;
  border: 2px solid var(--primary) !important; 
}

.btn-outline {
  border: 2px solid var(--black) !important;
}
.btn-outline:hover {
  color: var(--primary);
  border: 2px solid var(--primary) !important;
}

.btn-sm{
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}


.banner{
  min-height:100vh;
}

.banner-90{
  min-height:90vh;
}

.banner h2.white, .section-title.white, .section-subtitle.white, .large-text.white{
  color: var(--white)
}
.banner h2.black, .section-title.black, .section-subtitle.black{
  color: var(--black)
}

.section-subtitle.yellow, .section-subtitle .yellow{
  color: var(--primary)
}

.banner h1{
  font-size:3.4rem;
  font-family: 'SofiaPro Bold';
  margin:0;
  color: var(--black)
}
.banner h2{
  font-size:3rem;
  font-family: 'SofiaPro Bold';
  margin:0;
}

.highlight{
  position: relative;
  box-shadow:
    inset 0 -0.175em var(--white),
    inset 0 -0.31em var(--primary);
}

.green.highlight{
  box-shadow:
    inset 0 -0.175em var(--white),
    inset 0 -0.31em var(--dark-green) !important;
}
.red.highlight{
  box-shadow:
    inset 0 -0.175em var(--white),
    inset 0 -0.31em var(--dark-red) !important;
}
.blue.highlight{
  box-shadow:
    inset 0 -0.175em var(--white),
    inset 0 -0.31em var(--dark-blue) !important;
}
.purple.highlight{
  box-shadow:
    inset 0 -0.175em var(--white),
    inset 0 -0.31em var(--dark-purple) !important;
}

.section-title{
  font-size: 3rem;
  margin: 0;
  font-family: 'SofiaPro Bold';
}

.section-subtitle{
  font-size: 1.7rem;
  margin: 0;
  font-family: 'SofiaPro SemiBold';
}

.bg-layer{
  background: var(--primary);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.2) 60%, rgba(0,0,0,0.2) 100%);
}
/* COMMON USED CLASSES ENDS */

/* CAROUSEL SLIDER START */
.carousel-control-prev {
  left: -50px;
}
.carousel-control-next {
  right: -50px;
}
.carousel-control-prev-icon {
  display: none;
  position: relative;
  left: -50px;
  background-image: url("images/career/prev.svg") !important;
}
.carousel-control-next-icon {
  display: none;
  position: relative;
  right: -50px;
  background-image: url("images/career/next.svg") !important;
}
.carousel.slide:hover .carousel-control-prev-icon,
.carousel.slide:hover .carousel-control-next-icon {
  display: block;
}
/* CAROUSEL SLIDER END */


/* NAVIGATION */
.navigation-menu{
  position: relative;
}
.navigation-menu .navbar-brand{
  width:50px;
  height:50px;
  background: var(--logo-red);
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 3rem;
}
.navigation-menu .navbar-brand svg{
  width:18px;
  margin-left: -5px;
}
.navigation-menu .nav-item a{
  color: var(--black);
  margin-right: 2rem;
  text-decoration: none;
}

.navigation-menu .nav-item a.active{
  font-weight: bold;
  box-shadow:
    inset 0 -0.32em var(--dark-blue);
}

.service_nav.active{
  box-shadow:
    inset 0 -0.32em var(--primary) !important;
}
.about_nav.active{
  box-shadow:
    inset 0 -0.32em var(--dark-purple) !important;
}
.careers_nav.active{
  box-shadow:
    inset 0 -0.32em var(--dark-green) !important;
}
.ideas_nav.active{
  box-shadow:
    inset 0 -0.32em var(--primary) !important;
}


.navbar{
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  transition: .3s;
}

.navbar-light.is-stuck.true{
  background: var(--white);
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,.1);
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}
.navbar-nav .btn{
  padding:13px 3rem;
}

.headroom-wrapper{
  height: 0 !important;
}
.headroom{
  z-index: 12 !important;
  height: 66px !important;
}
/* NAVIGATION ENDS */

/* RESPONSIVE NAVIGATIONS */
.toggler-button{
  z-index: 9999;
  top: 0;
  left: 0;
  transition:0.3s;
}

.toggler-button.is-stuck.true{
  background-color: #fff;
}

.responsive-navbar .navbar-nav{
  width:100%;
  display: inline-block;
  text-align:center;
  transition:1.3s;
}

.responsive-navbar .navbar-nav li a{
  font-size: 2rem;
  padding: 1rem;
  display: inline-block;
  text-decoration: none;
  color: var(--black);
}
.responsive-navbar .navbar-nav li a:hover, .responsive-navbar .navbar-nav li a.active{
  color: var(--primary);
}

.hamburger{
  width: 46px;
  height:46px;
  padding:0;
  border: 0;
  position: relative;
}

.line1, .line2{
  width: 30px;
  height:5px;
  margin:0.5rem;
  display:block;
  background: var(--dark-red);
  border-radius: 3px;
  transition: 0.3s;
}
.modal-open .line1{
  transform:rotate(45deg);
  background: var(--dark-red);
  margin-top:0;
  margin-bottom:-4px;
}
.modal-open .line2{
  transform:rotate(-45deg);
  margin-top:-4px;
  margin-bottom:0;
}

.is-stuck.true .line1, .is-stuck.true .line2{
  background: var(--dark-red);
}

.modal-dialog.responsive-navbar{
  width:100%;
  max-width:100%;
  height:100%;
  margin:0;
}
.responsive-navbar .modal-content{
  border:0 !important;
  border-radius:0 !important;
  align-items:center;
  text-align:center;
  flex-direction: unset;
  height: 100%;
}

.navbar-brand.close-menu{
  position: absolute;
  top:0;
  left: 15px;
  opacity: 0;
  visibility: hidden;
}
.modal-open .navbar-brand.close-menu{
  visibility: visible;
}
/* RESPONSIVE NAVIGATIONS EDS */

/* FOOTER */
.footer{
  background: var(--black);
}
.footer h2{
  color: var(--primary);
  font-family: 'SofiaPro Bold';
  margin-top:0;
  margin-bottom: 1.5rem;
}
.footer h3{
  color: var(--primary);
  font-family: 'SofiaPro Regular';
  margin-top:0;
  margin-bottom: 1rem;
}

.footer p, .footer a{
  color: #bbb;
  font-family: 'SofiaPro Light';
  margin-top:0;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-decoration: none;
  width: 100%;
  display: inline-block;
}
.footer a:hover{
  color: var(--primary);
}
.social-connect a{
  width: auto;
  margin-right: 1.8rem;
  font-size: 20px;
  color: var(--primary);
  transition:0.3s;
}
.social-connect a:last-child{
  margin-right: 0rem;
}
.social-connect a:hover{
  color: var(--white);
}
.sub-footer{
  border-top: 1px solid rgba(255,255,255,0.3);
}
.sub-footer p, .sub-footer a{
  font-size: 0.9rem;
  /*width: 100%;*/
  display: inline-block;
  margin-bottom:0;
  font-family: 'SofiaPro Regular'
}
.sub-footer a{
  text-decoration: underline;
}
.sub-footer li {
  font-size: 0.9rem;
  display: inline-block;
  padding-right: 10px;
}

.footer ul li {
  padding-left: 0;
  list-style: none;
}
/* FOOTER ENDS */

/* GET IN TOUCH SECTION */
.get-in-touch h3{
  margin: 1rem 0;
  font-family: 'SofiaPro Light';
  font-size: 1.5rem;
}

.get-in-touch a{
  position: relative;
  margin: 0;
  font-size: 4rem;
  font-family: 'SofiaPro Bold'; 
  width: auto;
  display: inline-block;
  text-decoration: none;
  color: var(--black);
  z-index: 1;
  line-height: normal;
  letter-spacing: -1px;
}

.get-in-touch a:after{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width:100%;
  height:4px;
  background: var(--primary);
  transition: 0.35s ease-in;
  z-index: -1;
}

.get-in-touch a:hover::after{
  height:55%;
}

.get-in-touch img{
  width: 58px;
}
/* GET IN TOUCH SECTION  ENDS*/

/* HOME PAGE */
.home--banner-bg{
  /* background-image: url("./images/home/banner-home.svg"); */
  background-position: top right;
  background-repeat: no-repeat;
}
.banner-home{
  height:100vh;
  position: relative;
}

.project-image{
  display: inline-block;
  width: 100%;
  /*border-radius:20px;*/
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  overflow: hidden;
}
.project-image img{
  width: 100%;
}

.project-title{
  font-family: 'SofiaPro Medium';
  font-size:2rem;
  margin-bottom: 1.5rem;
}

.large-text{
  font-family: 'SofiaPro Light';
  font-size:1.5rem;
  margin-bottom: 1.5rem;
}
.project-link{
  font-family: 'SofiaPro Regular';
  font-size:1.2rem;
  margin-bottom: 0;
  line-height: normal;
  position: relative;
  text-decoration:none !important;
  color: var(--blue);
}

.project-link i{
  color: var(--blue);
}

.project-link svg{
  position: absolute;
  top: 0;
  right: -10px;
  transition:0.3s;
  opacity: 0;
}

.project-link:hover > svg{
  right: -25px;
  opacity: 1;
}

.expertise{
  transition: 0.3s;
  background: var(--white);
}

.expertise:hover{
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  cursor: pointer;
  z-index: 1;
}

.expertise-title{
  font-size:2rem;
  color: var(--black);
  margin-top:0;
  margin-bottom: 1.5rem;
  font-family: 'SofiaPro SemiBold';
}

.expertise-text{
  font-size:1.5rem;
  color: var(--black);
  margin:0;
  font-family: 'SofiaPro Light';
}


.howwedo{
  transition: 0.3s;
  background: var(--white);
}
.howwedo:hover{
  cursor: pointer;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  z-index: 1;
}

.testimonial-client {
  position: absolute;
  bottom: -50px;
  left: 25px;
  border: 3px solid #fff;
}
.videoPlay {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 123;
}
.ReactModal__Overlay {
  z-index: 123;
  background: rgba(0, 0, 0, 0.75) !important;
}
.ReactModal__Content {
  border-radius: 0 !important;
  overflow: inherit !important;
  background: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoWrapper {
  position: relative !important;
  z-index: 123456;
  text-align: center;
  display: inline-block;
  width: 900px;
  height: 630px;
}
.closeButtonModal {
  position: absolute;
  top: -40px;
  right: -40px;
  width: 40px;
  height: 40px;
  z-index: 123456;
  cursor: pointer;
  transition: transform .2s;
}
.closeButtonModal:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2); 
}
.videoWrapper iframe {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height:100%;
}

.processRight {
  position: relative;
  padding-right: 70px;
}
.processRight::after {
  position: absolute;
  content: '';
  right: 15px;
  top: 22px;
  width: 90px;
  height: 1px;  
  background-color: #000;
}
.processRight h1.section-title::after {
  content: '';
  position: absolute;
  right: 105px;
  top: 0;
  width: 1px;
  height: 46px;
  background: #000;
}

.process {
  position: relative;
  padding-left: 70px;
}
.process::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 40px;
  width: 1px;
  height: 100%;
  background: #000;
}
.process h2.project-title {
  position: relative;
}
.process h2.project-title::before {
  content: '';
  position: absolute;
  left: -100px;
  width: 32px;
  height: 32px;
  background-image: url('../assets/images/home/arrow.svg');
}
.process h5 {
  line-height: 1.9rem;
}
/* HOME PAGE ENDS 


/* Blog Section */
.blog-box{
  position: relative;
  transition: 0.3s;
}
.blog-box::before{
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  background: var(--black);
  left: 0;
  top:0;
}

.blog-box:last-child::after{
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  background: var(--black);
  right: 0;
  top:0;
}

.blog-subject a{
  color: var(--black);
  text-decoration: none;
  font-family: SofiaPro SemiBold;
  margin: 1.5rem 0;
  display: inline-block;
  transition: 0.3s;
}
.blog-data, .blog-data a{
  color: var(--black);
  font-family: 'SofiaPro Light';
  font-size: 0.9rem;
  margin: 0;
  transition: 0.3s;
}

.blog-box:hover{
  background: var(--black);
}
.blog-box:hover > .blog-subject a, .blog-box:hover > .blog-data, .blog-box:hover > .blog-data a{
  color: var(--white);
}

.custom-bullets{
  list-style: none;
}
.custom-bullets li{
  position: relative;
}
.custom-bullets li::before{
  content:'';
  position: absolute;
  left:-25px;
  top:12px;
  width:12px;
  height:12px;
  border-radius: 50%;
  background: var(--light-red);
  display: inline-block; 
}
.long-btn{
  width:100%;
  display: inline-block;
  padding:0.5rem;
  text-align:center;
  font-size:1.5rem;
  font-family: 'SofiaPro Regular';
  border-radius: 25px;
  border: 2px solid var(--red);
  color: var(--red);
  transition: 0.3s;
  text-decoration: none !important;
}
.long-btn:hover{
  background: var(--light-red);
  color: var(--white);
  border-color: var(--light-red);
}

.develop-app button{
  position: relative;
}

.shadowfilter {
  -webkit-filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0));
   filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0));
   transition: 0.5s;
}

.develop-app button:hover .shadowfilter{
  -webkit-filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));
   filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));
}

.bs-popover-auto[x-placement^=bottom]>.arrow::before, .bs-popover-bottom>.arrow::before, .bs-popover-auto[x-placement^=bottom]>.arrow::after, .bs-popover-bottom>.arrow::after{
  transform: skewX(-60deg);
}

.bs-popover-auto[x-placement^=top]>.arrow::before, .bs-popover-top>.arrow::before, .bs-popover-auto[x-placement^=top]>.arrow::after, .bs-popover-top>.arrow::after{
  transform: skewX(60deg);
}
.popover .arrow{
  left: 40px !important;
}
.popover-header{
  background: none;
  border: none;
}
/* Blog Section Ends */

/* Service Page Start */
.digital-marketing{
  background-image: url("./images/service/digital_marketing.png");
  background-position: center bottom;
  background-repeat: no-repeat;
}
.industries{
  transition: 0.3s;
}
.industries:hover{
  background: var(--primary);
}
.industries svg{
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
}

.industries h6{
  margin-bottom:0;
}
/* Service Page Ends */

/* Portfolio Page */
.portfolio{
  min-height: 100vh;
}

.project-theme-light *{
  color: var(--white) !important;
}

.project-theme-light .project-link img{
  filter: invert(100%); 
  -webkit-filter:invert(100%); 
}

.project-theme-dark *{
  color: var(--black) !important;
}

.portfolio .project-link{
  text-decoration: none;
  font-size: 2rem;
  font-family: 'SofiaPro Light';
  display: inline-block;
}
.portfolio .project-link img{
  width:40px;
  margin-right:10px;
}

.bg-arkiver-proj{
  background: #5AD377;
}
.bg-mobiprint-proj{
  background: #ea5862;
}
.bg-hensler-proj{
  background: #898989;
}
.bg-vit-proj{
  background: #b7d331;
}
.bg-paybychex-proj{
  background: #1785ce;
}
.bg-limocious-proj{
  background: #6f539a;
}

/*a.works {
  overflow: hidden;
}
a.works img {
  display: block;
  border-radius: 15px;
}
a.works img:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}*/

.cus-arrow {
  width: 30px !important;
  height: auto;
}
a.works {
  display: block;
  color: #000;
  text-decoration: none;
  background: #fff;
  transition: all 0.4s ease-in-out;
}
a.works:hover {
  cursor: pointer;
  box-shadow: 0 6px 30px rgba(0,0,0,0.08);
}
a.works:hover, a.works:focus {
  text-decoration: none;
}
a.works img {
  width: 100%;
}
a.works .card {
  border: none;
}
/* Portfolio Page Ends */

/* About us Page */
.testimonials .blog-subject a{
  font-size: 1.8rem;
}

.BigText {
  width: 80px;
  font-size: 6rem;
  color: var(--red);
  font-weight: 700;
  position: relative;
  top: -3px;
  left: 10px;
  line-height: normal;
}
/* About us Page Ends */

/* Contact us Page */
.form-field .form-control{
  background: transparent;
  border-radius: 0;
  outline: 0;
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid var(--black);
  padding: 1rem 0;
  font-size: 1.5rem;
  font-family: 'SofiaPro Light';
}

.form-field select.form-control{
  border:0;
  outline: 1px solid var(--black);
  padding:0;
  cursor: pointer;
}

.form-field label{
  padding: 1rem 0;
  font-size: 1.5rem;
  font-family: 'SofiaPro Light';
  width:100%;
  display:inline-block;
}
.contact-social ul{
  margin-bottom:0; 
}
.contact-social ul li{
  display: inline-block;
  margin:1rem 0;
}

.contact-social ul li a{
  font-size: 1.2rem;
  color: var(--white);
  text-decoration: none;
  display: flex;
  align-items: center;
  transition:0.3s;
}

.contact-social ul li a svg{
  font-size: 3rem;
  width:70px !important;
}

.contact-social ul li a:hover{
  color: var(--primary);
}
/* Contact us Page Ends */

/* Blog Page */
.blog-slider img{
  width:100%;
  height:auto;
}
.blog-slider a, .blog-slider a:hover{
  text-decoration: none;
}
.carousel-indicators{
  bottom: -50px;
  margin:0;
  padding: 0;
}
.carousel-indicators li{
  width:9px;
  height:9px;
  border-radius:50%;
  border:2px solid var(--gray);
  background: transparent;
  opacity:1;
}
.carousel-indicators li.active{
  background: var(--gray);
}

.blog-post a .card{
  height: 100%;
}

.blog-post a .card, .blog-post a .card-img-top{
  border:0;
  border-radius:0;
  transition: 0.3s;
}

.blog-post a:hover > .card{
  box-shadow: 0 6px 30px rgba(0,0,0,0.08);
}

.blogpost-title{
  font-family: 'SofiaPro Light';
  color: var(--black);
}

.blogpost-date{
  font-family: 'SofiaPro Regular';
  font-size:15px;
  color: var(--gray);
}

.blogpost-text{
  font-family: 'SofiaPro Regular';
  color: var(--black);
}

.blog-post a{
  text-decoration: none;
}

.blog-pagination .page-link{
  position: relative;
  display: inline-block;
  padding: 0;
  color: var(--black);
  line-height: normal;
  border-radius: 50%;
  width:30px;
  height:30px;
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
}

.blog-pagination .page-link.active{
  background-color: var(--black);
  color: var(--white);
}

.blog-pagination .page-item:first-child .page-link, .blog-pagination .page-item:last-child .page-link{
  border-radius: 50%;
}

.blogQuoteBlk {
  font-size: 1.2rem;
  line-height: 2rem;
  position: relative;
  font-family: 'SofiaPro SemiBold';
  padding: 3rem 4rem;
  color: var(--black);
  letter-spacing: -1px;
  z-index: 2;
}
.blogQuoteBlk::before{
  content: '';
  background: url("./images/home/quoteBlk.png");
  background-size: 44px;
  background-repeat: no-repeat;
  width: 44px;
  height: 29px;
  position: absolute;
  top: 18px;
  left: 15px;  
  z-index: 0;
}

.blog-quote{
  position: relative;
  font-family: 'SofiaPro SemiBold';
  /* font-style: italic; */
  /* border-left: 8px solid var(--primary); */
  margin: 2rem 3rem 2rem 3rem;
  padding: 1rem 0rem 0rem 3rem;
  color: var(--primary);
  font-size:3rem;
  line-height:3.5rem; 
  letter-spacing: -1px;
  z-index: 2;
}

.blog-quote.lg{
  font-size:3rem;
  line-height:3.5rem; 
}

.blog-quote.md{
  font-size:2.5rem;
  line-height: 3.3rem;
}

.blog-quote.sm{
  font-size:2rem;
  line-height: 3rem;
}

.blog-quote::before{
  content: '';
  background: url("./images/blogs/left-quote-gray.svg");
  background-size: 40px;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  left: 0;  
  z-index: 0;
}
.blog-quote .author {
  position:reative;
  padding-top:1rem;
  font-size: 1.5rem;
  line-height: normal;
  display: inline-block;
  width: 100%;
  font-family: "SofiaPro Regular";
  position: relative;
  color: var(--gray);
}
.blog-quote .author::after{
  content: '';
  position: absolute;
  top:0;
  left:0;
  width:1.5rem;
  height:2px;
  background: var(--gray);
}

.hash-tag{
  font-family: 'SofiaPro SemiBold';
  color: var(--light-red);
  font-size:1.5rem;
}

.back-arrow-link, .blog-left-sidebar a, .blog-bottom-bar a{
  color: var(--black);
  text-decoration: none !important;
  margin-bottom:0.5rem;
  display: inline-block;
  transition: 0.3s;
  line-height: normal;
}
.back-arrow-link img{
  width:17px;
  margin-right:0.5rem;
}
.back-arrow-link:hover, .blog-left-sidebar a:hover, .blog-bottom-bar a:hover, .share-btn:hover, .share-btn:hover > svg{
  color: var(--primary);
}
.back-arrow-link:hover > svg {
  fill: var(--primary);
  transition: 0.3s;
}
.our-voice .custom-bullets li{
  display: inline-block;
  width: 100%;
}
.blog-left-sidebar{
  position: relative;
  text-align: center;
}
.blog-left-sidebar p{
  font-size: 14px; 
}
.sticky-top{
  position: sticky;
  top:100px;
}
.sticky-top-200 {
  position: sticky;
  top: 200px;
}
.sticky-top-300 {
  position: sticky;
  top: 300px;
}

.blog-bottom-bar{
  position:sticky;
  bottom: 0;
  background-color: var(--white);
  z-index: 1020;
  padding: 15px;
  display:none;
  border-top: 1px solid var(--silver)
}

.sharing-button-bar{
  /* position: absolute;
  right:1.3rem;
  bottom:100%; */
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.sharing-button-bar p{
  font-size: 12px;
  margin-bottom:0 !important;
}

.sharing-button-bar .bg-layer{
  position: absolute;
  top:0;
  left: 0;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.3);
  z-index:1;
}
.copy-link-icon{
  background: var(--black);
  color: var(--white);
  width:32px !important;
  height:32px;
  border-radius: 50%;
  padding: 9px;
  vertical-align: bottom;
  cursor: pointer;
}
.copy-link-icon:hover{
  background: var(--blue) !important;
  color: var(--white) !important;
}

/* .copy-link-icon{
  Background: var(--white) !important;
  width:32px !important;
  height:32px;
  border-radius: 50%;
  padding: 8px !important;
  cursor: pointer;
  border:1px solid var(--black) !important;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.copy-link-icon svg{
  color: var(--black);
  font-size:0.8rem;
}

.share-on-social-media svg{
  transition:0.3s;
}
.share-on-social-media svg:hover{
  transform:scale(1.1);
}
.next-prev-blog a{
  text-decoration: none;
  color: var(--black)
}

.next-prev-blog a:hover > p{
  text-decoration: underline;
}

.our-voice p{
  margin-bottom: 1.2rem;
}
.our-voice h3{
  line-height: normal;
  display: inline-block;
  width:100%;
}
.page-progress-bar{
  z-index: 1510 !important;
}

.blog-bottom-bar a{
  margin-bottom: 0;
}
.share-btn{
  padding:0;
  background: var(--white) !important;
  vertical-align: bottom;
  cursor: pointer;
  border: none;
  color: var(--black);
  text-transform: unset;
  font-family: 'SofiaPro Light';
  font-size:1.1rem;
  line-height: normal;
  transition:0.3s;
}

.share-btn img{
  width:17px;
  margin-left:0.5rem;
}

.related-posts .blogpost-date, .related-posts .blogpost-text{
  display: none;
}
/* Blog Page Ends */

/* Career Page */
.vacancy-detail h3{
  font-family: 'SofiaPro Medium';
  font-size: 2rem;
  margin: 0 0 1rem 0;
}

/*.vacancy-detail .custom-bullets li::before{
  width:8px;
  height:2px;
  background: var(--black);
  border-radius: 0;
  top:11px;
}*/
.custom-bullets li::before{
  width: 10px;
  height: 10px;
  background: var(--primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  top: 10px;
}

.vacancy-detail ul li{
  font-family: 'SofiaPro Light';
  margin-bottom: 1rem;
}

.vacancy-detail .border-top{
  border-top: 2px solid var(--black) !important;
}

.job-title{
  font-family: 'SofiaPro Medium';
  font-size: 2.5rem;
  margin: 0;
}

.vacancy-title{
  font-family: 'SofiaPro Medium';
  font-size: 1.5rem !important;
  margin: 0 1rem 0 0 !important;
  width:100%;
  display: inline-block;
}

.vacancy-desc{
  font-family: 'SofiaPro Light';
  font-size: 1.5rem;
  margin: 0;
  width:100%;
  display: inline-block;
}

.vacancy-details-form{
  border-top: 2px solid var(--black);
  position: relative;
}

.vacancy-link{
  width:100%;
  display:inline-block;
  font-family: 'SofiaPro Regular';
  font-size:2.2rem;
  margin-bottom: 0;
  line-height: normal;
  position: relative;
  text-decoration:none !important;
  color: var(--black) !important;
  padding: 1.5rem 1rem;
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  margin-top:-1px;
  /*transition:0.3s;*/
}

.vacancy-link p{
  font-size: 1.2rem;
  font-family: 'SofiaPro Light';
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4.5rem;
  transition:0.3s;
  /*opacity: 0;*/
  color: var(--black);
  margin:0;
}
/*.vacancy-link p::after{
  content:'';
  position: absolute;
  width:1.8rem;
  height:1px;
  top:50%;
  transform: translateY(-50%);
  right:-2rem;
  background: var(--black);
}
.vacancy-link p::before{
  content: '';
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  right:-2.1rem;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-left: 8px solid var(--black);
  border-bottom: 4px solid transparent;
}*/
.vacancy-link:hover > p{
  /*right: 3rem;*/
  opacity: 1;
}
.vacancy-link img{
  width:70px;
}
.vacancy-link:hover{
  /*padding-left:1.5rem;*/
}
.jobBackArrow {
  width: 20px;
  transform: rotate(-180deg);
  position: relative;
  top: -2px;
}

.toolsUse svg, .toolsUse img{
  width: 92px;
  height: 92px;
}

.team-quality{
  transition: 0.3s;
}

.team-quality:hover{
  background: var(--primary);
}

.team-quality svg{
  width:80px;
}

.team-quality h2{
  font-family: 'SofiaPro Medium';
  font-size: 1.8rem;
  margin: 1.5rem 0 0;
  line-height: normal;
}

.cards h2{
  font-family: 'SofiaPro Medium';
  margin: 1.5rem 0 0;
  line-height: normal;
}

.cards p{
  margin: 1.5rem 0 0;
}
/* Career Page Ends */

/* Case Studies Page */
.technologies-used img{
  filter: grayscale(100%);
  transition:0.3s;
}

.technologies-used img:hover{
  filter: grayscale(0);
}
/* Case Studies Page Used */

/* Privacy & Terms */
.terms-privacy h4{
  font-size:1.75rem;
  font-family: 'SofiaPro Light';
}

.terms-privacy a{
  color: var(--blue);
}
/* Privacy & Terms Ends */

/* Hireus Page */
.form-check{
  padding-left:1.8rem;
}  
.form-check-input{
  width:20px;
  height:20px;
  margin-left:-1.8rem;
  margin-top:1px;
}
.form-check-input:after{
  content:'';
  position:absolute;
  width: 100%;
  height:100%;
  top: 0;
  Left: 0;
  background: var(--white);
  border: 2px solid var(--black);
}

.form-check-input:checked:before{
  content:'';
  position:absolute;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid var(--black);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index:2;
}


.hireus-modal{
  width:100%;
  max-width:100%;
  height:100%;
  margin: 0;
  padding: 0;
  text-align: center;
}
.hireus-modal .modal-content{
  width:100%;
  height: 100%;
  display: flex;
  border:0;
  border-radius: 0;
}
.hireus-modal .closeButtonModal{
  position: absolute;
  top: 75px;
  right: 75px;
  width: 40px;
  height: 40px;
  z-index: 123456;
  cursor: pointer;
}
/* Hireus Page Ends */

/* Blog page share buttons */
.react-share__ShareButton[aria-label="facebook"] svg circle{
  fill: var(--black);
}
.react-share__ShareButton[aria-label="facebook"]:hover svg circle{
  fill: #3b5998;
}
.react-share__ShareButton[aria-label="twitter"] svg circle{
  fill: var(--black);
}
.react-share__ShareButton[aria-label="twitter"]:hover svg circle{
  fill: #00aced;
}
.react-share__ShareButton[aria-label="linkedin"] svg circle{
  fill: var(--black);
}
.react-share__ShareButton[aria-label="linkedin"]:hover svg circle{
  fill: #007fb1;
}
.react-share__ShareButton[aria-label="email"] svg circle{
  fill: var(--black);
}
.react-share__ShareButton[aria-label="email"]:hover svg circle{
  fill: #7f7f7f;
}


.react-share__ShareButton svg path{
  fill: var(--primary);
}
.react-share__ShareButton svg:hover path{
  fill: var(--white);
}

svg.copy-link-icon path {
  fill: var(--primary);
}
svg.copy-link-icon:hover path {
  fill: var(--white);
}

/* RESPONSIVE MEDIA QUERIES */
@media screen and (max-width: 1500px){
  /* .banner h1{
    font-size: 3.4rem;
    line-height:63px;
  } */
}

@media screen and (max-width: 1200px){
  .banner h1{
    font-size: 3.2rem;
    line-height:58px;
  }
  .blog-subject{
    font-size:2rem;
  }
}

@media screen and (max-width: 991px){
  .blog-box::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: var(--black);
    right: 0;
    top: 0;
  }
  .banner h1{
    font-size: 3rem; 
    line-height:52px;   
  }
  
  .headroom-wrapper{
    height: 82px !important;
  }
  .headroom{
    height: 0 !important;
  }

  .blog-left-sidebar{
    display: none;
  }
  .blog-bottom-bar{
    display: block;
  }
  .sharing-button-bar .share-on-social-media{
    position: absolute;
    width:100%;
    left:0;
    bottom:-100%;
    transition:0.3s;
    margin:0;
    background: var(--white);
    z-index: 999;
  }  
  .collapse.show .share-on-social-media{
    bottom: 0;
  }
}

@media screen and (max-width: 768px){
  .headroom-wrapper{
    height: 0 !important;
  }
  .navbar{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .navbar-light{
    background: var(--white);
    text-align: center;
  }
  .get-in-touch a{
    font-size:3.5rem;
  }

  .banner h2, .section-title, .blog-subject, .job-title{
    font-size: 2rem;
  }

  .vacancy-detail h3, .section-subtitle{
    font-size: 1.5rem;
  }

  .testimonials .blog-quote{
    padding: 1rem;
  }
  .testimonials .blog-quote::before{
    top: 0.8rem;
  }

  .blog-quote{
    margin:1rem;
    padding: 0 0 0 1rem;
  }

  .blog-quote.lg{
    font-size: 2rem;
    line-height:3rem;
  }
  
  .blog-quote.md{
    font-size:1.8rem;
    line-height: 2.8rem;
  }
  
  .blog-quote.sm{
    font-size:1.5rem;
    line-height: 2.5rem;
  }

  .blog-quote::before{
    background-size: 25px;
    height: 25px;
    width: 25px;
    top: -0.3rem;
    left: -1rem;
  }

  .blog-subject{
    font-size:1.6rem;
  }

  .mtb-80 {
    margin: 30px 0;
  }

  .pl-90{
    padding-left:15px;
  }
  .videoWrapper {
    width: 100%;
    height: 85%;
  }
  .blogQuoteBlk {
    padding: 3.3rem 1rem;
  }
  .closeButtonModal {
    top: -28px;
    right: -28px;
    width: 28px;
    height: 28px;
  }
}

@media screen and (max-width: 767px){
  .processRight{
    padding-right: 0;
  }
  .processRight::after, .processRight h1.section-title::after {
    display:none;
  }
  .process{
    padding-left: 50px;
  }
  .process:last-child::before {
    display:none;
  }
  .process::before {
    left: 10px;
  }
  .process h2.project-title::before {
    left: -55px;
  }
}

@media screen and (max-width: 500px){
  .banner h1{
    font-size: 2.5rem;
    line-height:58px;
  }
  .expertise-title, .project-title{
    font-size:1.7rem;
    margin-bottom: 1rem;
    line-height: normal;
  }
  
  .expertise-text, .vacancy-link, .get-in-touch h3{
    font-size:1.2rem;
  }
  .large-text{
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .get-in-touch a {
    font-size: 2.5rem;
  }

  .testimonials .blog-quote::before{
    top: 0.3rem;
  }

  .blog-quote{
    margin: 1rem;
    margin-right:0;
    padding: 0 0 0 1rem;
  }
  .blog-quote.lg{
    font-size: 1.8rem;
    line-height:2.5rem;
  }
  
  .blog-quote.md{
    font-size:1.5rem;
    line-height: 2.2rem;
  }
  
  .blog-quote.sm{
    font-size:1.2rem;
    line-height: 2rem;
  }

  .blog-quote.lg .author{
    margin-top:14px;
  }
  .blog-quote.md .author{
    font-size: 1.3rem;
    margin-top:14px;
  }
  .blog-quote.sm .author{
    font-size: 1.1rem;
    margin-top:10px;
  }

  .cards h2{
    margin: 1rem 0 0;
  }
  .cards p {
    margin: 0.5rem 0 0;
  }
  .form-field .form-control{
    font-size: 1rem;  
  }
  .contact-social ul li a svg {
    font-size: 2rem;
    width: 45px !important;
  }

  .vacancy-link img{
    width:40px;
  }

  .vacancy-link p{
    display: none;
  }
}